import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Desc = ({ title, description, keywords }) => {
  const { pathname } = useLocation();
  const newPath = pathname.split("/")[1].toLocaleUpperCase();
  return (
    <Helmet>
      <title>{`${newPath === "" ? "Home" : newPath} | Nabafat.AI`}</title>
      <meta name={`${newPath}...`} content={description} />
    </Helmet>
  );
};

export default Desc;
