export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const cards = [
  {
    id: 0,
    logo: "/assets/images/bot.png",
    label: "Automated AI Chatbots",
    text: "We create AI chatbots for any kind of website to help you streamline your communication process.",
  },
  {
    id: 1,
    logo: "/assets/images/vurtual.png",
    label: "Virtual Reality",
    text: "Building virtual reality is our forte. We can provide you with an outstanding virtual reality project.",
  },
  {
    id: 2,
    logo: "/assets/images/ML.png",
    label: "Machine Learning",
    text: "Defending yourself against cyber attacks is best done with advanced, self-learning artificial intelligence. We offer custom AI-based security services.",
  },
  {
    id: 3,
    logo: "/assets/images/internet.png",
    label: "Internet of Things",
    text: "For cloud infrastructure, we have a highly trained team to automate your business development with AI.",
  },
  {
    id: 4,
    logo: "/assets/images/core.png",
    label: "Core Modernization",
    text: "Core modernization support before deploying any project and restructuring AI model pipeline for any type of team.",
  },
  {
    id: 5,
    logo: "/assets/images/ai.png",
    label: "Cyber Security With AI",
    text: "Defending yourself against cyber attacks is best done with advanced, self-learning. We offer AI security services.",
  },
];

export const finance = [
  {
    id: 0,
    label: "Cyber-threat detection",
    text: "Help information security teams reduce risk and improve their security posture efficiently and effectively",
  },
  {
    id: 1,
    label: "Cash Optimization",
    text: "Create an intelligent cash management system based on cash demand forecasting; predict cash demand",
  },
  {
    id: 2,
    label: "Accounting and Auditing",
    text: "Transform auditing from quarterly to real-time, driving audit quality, accuracy and reliability",
  },
  {
    id: 3,
    label: "Employee Retention",
    text: "View predictions of employee departure, forecast churn rates and identify relevant factors contained in employee data",
  },
  {
    id: 4,
    label: "Anomaly Detection",
    text: "This time series process looks at expected data volumes based on historical patterns. Upper and lower boundaries...",
  },
];

export const customer = [
  {
    id: 0,
    label: "Account Opening / Card Issuing",
    text: "Promote products (accounts, cards) with more relevancy to boost customer acquisition",
  },
  {
    id: 1,
    label: "Credit Scoring",
    text: "Help underwriters evaluate creditworthiness using alternate data sources; predict credit potential",
  },
  {
    id: 2,
    label: "Fair Lending and Loan Predictions",
    text: "Simplify the process of predicting whether the customer will default",
  },
  {
    id: 3,
    label: "Buy Now, Pay Later (BNPL)",
    text: "Harness alternative data beyond traditional credit checks to support credit...",
  },
  {
    id: 4,
    label: "Debt Repayment",
    text: "Model prepayment risk and automate document classification to predict repayments/prepayment risks",
  },
];

export const offers = [
  {
    id: 0,
    label: "Product Recommendations",
    text: "Understand the relationship between various products in a catalog and generating...",
  },
  {
    id: 1,
    label: "Contextual, Cookieless Targeting",
    text: "Help marketing teams at FIs achieve more awareness and achieve higher conversions",
  },
  {
    id: 2,
    label: "Behavioral Targeting",
    text: "Send messages/content at the right time with the right content based on individual and collective customer...",
  },
  {
    id: 3,
    label: "Propensity to Buy",
    text: "Expand offers to customers with accuracy and speed by implementing predictive analytics",
  },
  {
    id: 4,
    label: "Direct Mail Marketing",
    text: "Develop better customer profiles that will target direct mailing, optimizing costs behind direct in-home mailing",
  },
];

export const retention = [
  {
    id: 0,
    label: "Customer Handling and Call Center Optimization",
    text: "Improve customer experience and reduce handling costs; map the query by the customer to appropriate...",
  },
  {
    id: 1,
    label: "Customer Churn",
    text: "Predict customer churn based on behavioral patterns and cadence of transactions; Identify leading indicators for churn",
  },
];

export const fraud = [
  {
    id: 0,
    label: "Application / Account Opening Fraud",
    text: "More accurately score applicants for fraud to drive down manual reviews...",
  },
  {
    id: 1,
    label: "Check Fraud",
    text: "Quickly and accurately detect fraudulent alterations (eg, counterfeit, forged) in bank checks",
  },
  {
    id: 2,
    label: "Collision Fraud",
    text: "Detect buyer and seller collusion; analyze acticities, accounts addresses, device IDs, coordinates, ...",
  },
  {
    id: 3,
    label: "Transaction Fraud",
    text: "Find new ways to improve fraud detection accuracy and detection time across multiple transaction fraud types",
  },
  {
    id: 4,
    label: "Anti-Money Laundering",
    text: "Leverage ML to more accurately spot suspicious and criminal behavior; balance compliance and cost by assessing the risk...",
  },
];

export const providers = [
  {
    id: 0,
    label: "Referral Automation",
    text: "Businesses and enterprises have lots of unstructured data - especially documents and image scans...",
  },
  {
    id: 1,
    label: "Hospital Capacity Simulator",
    text: "Hospital capacity simulation solution aims to predict census, hospital capacity, and patient stay.",
  },
  {
    id: 2,
    label: "Gene Mutation AI",
    text: "H2O.ai’s Gene Mutation AI offers a portable personalized medicine interface that can offer an individual’s genetic risk on demand...",
  },
  {
    id: 3,
    label: "Chest Abnormality Detection",
    text: "Chest X-Rays (CXRs) are widely used for diagnosing abnormalities in the heart and lung area. Automatically detecting these...",
  },
  {
    id: 4,
    label: "Clinical Decision Support for Heart Disease",
    text: "Clinical decision support for heart disease has been created to assist in early diagnosis of heart diseases which can also help...",
  },
  {
    id: 5,
    label: "Predicting Hospital-Acquired Infections",
    text: "An estimated one in 10 patients acquires an infection while hospitalized. This solution can monitor hospital...",
  },
  {
    id: 6,
    label: "Site Selection",
    text: "AI can be leveraged to speed patient identification and recruitment, reduce site burden, and empower patient engagement...",
  },
  {
    id: 7,
    label: "Predicting Readmissions",
    text: "Readmission can be significantly reduced by taking steps while the patient is still hospitalized, defining different actions...",
  },
];

export const health = [
  {
    id: 0,
    label: "Predicting Flu Encounters",
    text: "Companies are leveraging AI, and machine learning to create infectious disease forecasting. For flu predictions...",
  },
  {
    id: 1,
    label: "COVID Forecasts",
    text: "Using state-wide health information exchange data, ML models can actually predict a patient's likelihood...",
  },
  {
    id: 2,
    label: "COVID-19 Vaccination Progress Monitor",
    text: "The solution would provide transparency into COVID-19 vaccination trends around the world, created these interactive...",
  },
  {
    id: 3,
    label: "COVID-19 Vaccine Adverse Effects Predictor",
    text: "The COVID-19 vaccine adverse effects predictor solution, helps determine adverse effects...",
  },
];

export const coreValues = [
  {
    label: "Take Ownership",
    text: "Currently our team is lean and small. As we scale, we want to keep the tradition of taking ownership for your work. We trust our employees to be independent thinkers and doers.",
  },
  {
    label: "Be Direct",
    text: "The diversity of our workforce requires direct communication to minimize confusion and increase group coordination.",
  },
  {
    label: "Move Quickly And Persevere",
    text: "Competing in an emerging industry requires us to have grit. Our agile software philosophy allows us to move quickly and persevere through challenges.",
  },
  {
    label: "Show Integrity",
    text: "At Nabafat.ai, we work hard to foster a culture of honesty. Simply put, we ask that all employees do what they say they will do.",
  },
  {
    label: "Focus On Continuous Learning",
    text: "We are focused on incremental improvements in our system. This is also reflected in our day-to-day operations. Our employees encounter uncertainty with a growth mindset. Iteration and constant feedback are welcome.",
  },
];

export const positions = [
  {
    id: 1,
    label: "AI as a Service Provider",
    text: "Applied AI with lower initial investment and risk.",
  },
  {
    id: 2,
    label: "AI Application Developers",
    text: "DevOps, MLOps and cloud computing.",
  },
  {
    id: 3,
    label: "AI Talent",
    text: "Professionals, project-based and staff augmentation.",
  },
  {
    id: 4,
    label: "AI Though Leaders",
    text: "Community including visionaries, and open platforms.",
  },
];

export const team = [
  {
    id: 1,
    image: "/assets/images/bashir.jpg",
    name: "Bashir Mohammed",
    title: "Founder and CEO",
    link: "https://www.linkedin.com/search/results/all/?keywords=bashir%20mohammed%2C%20phd&origin=RICH_QUERY_SUGGESTI%20ON&position=0&searchId=e2f8236b-c1d8-4a3e-882d-1e2f71ad%20faf5&sid=SZI",
  },
  {
    id: 2,
    image: "",
    name: "name",
    title: "title",
    link: "https://www.linkedin.com/search/results/all/?keywords=bashir%20mohammed%2C%20phd&origin=RICH_QUERY_SUGGESTI%20ON&position=0&searchId=e2f8236b-c1d8-4a3e-882d-1e2f71ad%20faf5&sid=SZI",
  },
  {
    id: 3,
    image: "",
    name: "name",
    title: "title",
    link: "https://www.linkedin.com/search/results/all/?keywords=bashir%20mohammed%2C%20phd&origin=RICH_QUERY_SUGGESTI%20ON&position=0&searchId=e2f8236b-c1d8-4a3e-882d-1e2f71ad%20faf5&sid=SZI",
  },
  {
    id: 4,
    image: "",
    name: "name",
    title: "title",
    link: "https://www.linkedin.com/search/results/all/?keywords=bashir%20mohammed%2C%20phd&origin=RICH_QUERY_SUGGESTI%20ON&position=0&searchId=e2f8236b-c1d8-4a3e-882d-1e2f71ad%20faf5&sid=SZI",
  },
];
