import React from "react";
import { useNavigate } from "react-router-dom";
import { MyButton } from "../../Hooks/TextInput";
import style from "./Banner.module.scss";
import Fade from "react-reveal/Fade";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <video
        src='/assets/vid.mp4'
        className={style.container_video}
        autoPlay
        loop
        muted
        poster='/assets/images/hero.jpg'
      />
      <div className={style.container_image}>
        <img
          src='/assets/images/hero.jpg'
          alt='ai'
          className={style.container_video}
        />
      </div>

      <Fade right big>
        <div className={style.container_content}>
          <h1 className={style.container_content_header}>
            Ready To Turn The AI
            <br /> Revolution To Your Advantage
          </h1>
          <p className={style.container_content_text}>
            Nabafat.AI is{" "}
            <span className={style.container_content_text_span}>
              a central platform
            </span>{" "}
            to manage vision intelligence inside smart machines. Successfully
            introduce AI in{" "}
            <span className={style.container_content_text_span}>
              dynamic environments
            </span>{" "}
            without the need for AI experts.
          </p>
          <MyButton
            text='Request a demo'
            btnClass={style.container_content_btn}
            onClick={() => navigate("/demo")}
          />
        </div>
      </Fade>
    </div>
  );
};

export default Banner;
