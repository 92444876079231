import React from "react";
import style from "./About.module.scss";
import { AiFillLinkedin } from "react-icons/ai";
import { Col, Image, Row } from "react-bootstrap";
import { coreValues, positions, team } from "../../Hooks/data";
import { MyButton } from "../../Hooks/TextInput";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <div className={style.main}>
      <div className={style.main_container}>
        <Image
          src='assets/images/header.jpg'
          alt='career'
          className={style.main_container_bg}
        />
        <h3 className={style.main_container_text}>About Nabafat.AI</h3>
      </div>

      <p className={style.main_about}>
        Nabafat.AI delivers best-in-class robotic piece-picking solutions for
        e-commerce where key challenges include high SKU inventories, complex
        packaging, and fragile items. In the rapidly evolving world of logistics
        technology, Nabafat.AI offers smarter automation, low-risk business
        plans, and its signature Hypercare support from exploration to
        installation. Nabafat.AI intelligent robots enable goods-to-robot (G2R)
        use cases, where the greatest gains are yet to be made on the automated
        warehouse floor. Its machine-learning vision and control software
        provides the foundation for all its products.{" "}
      </p>

      {/* <hr />

      <h2 className={style.main_meet}>Meet The Team</h2>
      <div className={style.main_team}>
        {team.map((t) => (
          <div key={t.id} className={style.main_team_card}>
            <Image
              src={t.image}
              alt={t.name}
              className={style.main_team_card_image}
            />
            <p>{t.name}</p>
            <p>{t.title}</p>
            <AiFillLinkedin
              onClick={() => window.open(t.link, "_blank")}
              size={30}
              color='#2596be'
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default About;
