import React from "react";
import style from "./Services.module.scss";
import { Col, Image, Row } from "react-bootstrap";
import { MyButton } from "../../Hooks/TextInput";
import { useNavigate } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Flash from "react-reveal/Flash";

const Services = () => {
  const navigate = useNavigate();
  const cards = [
    {
      id: 0,
      logo: "/assets/images/bot.png",
      label: "Automated AI Chatbots",
      text: "We create AI chatbots for any kind of website to help you streamline your communication process.",
    },
    {
      id: 1,
      logo: "/assets/images/vurtual.png",
      label: "Virtual Reality",
      text: "Building virtual reality is our forte. We can provide you with an outstanding virtual reality project.",
    },
    {
      id: 2,
      logo: "/assets/images/ML.png",
      label: "Machine Learning",
      text: "Defending yourself against cyber attacks is best done with advanced, self-learning artificial intelligence. We offer custom AI-based security services.",
    },
    {
      id: 3,
      logo: "/assets/images/internet.png",
      label: "Internet of Things",
      text: "For cloud infrastructure, we have a highly trained team to automate your business development with AI.",
    },
  ];
  return (
    <div className={style.container}>
      <Row md={12}>
        <Col md={4}>
          {" "}
          <Slide left>
            <div className={style.container_contents}>
              <h5 className={style.container_contents_header}>What we do</h5>
              <p className={style.container_contents_semiheader}>
                Top-rated Services For Our Clients
              </p>
              <p>
                The best services we provide for our clients include our
                integrity to work for them and the dedication we show towards
                our individual projects.
              </p>
              <MyButton
                text='Check All Services'
                btnClass={style.container_contents_btn}
                onClick={() => navigate("/solutions")}
              />
            </div>
          </Slide>
        </Col>
        <Col md={8}>
          <div className={style.container_cards}>
            {cards.map((card) => (
              <Flash key={card.id}>
                <div className={style.container_cards_card}>
                  <Image
                    src={card.logo}
                    width={120}
                    height={120}
                    alt={card.label}
                  />
                  <p>{card.label}</p>
                  <p>{card.text}</p>
                  {/* <div>Learn More {">"}</div> */}
                </div>
              </Flash>
            ))}

            <MyButton
              text='Check All Services'
              btnClass={style.container_contents_btnMobile}
              onClick={() => navigate("/solutions")}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
