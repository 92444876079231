import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { MyButton, MyField } from "../../Hooks/TextInput";
import style from "./Demo.module.scss";
import { countries } from "../../Hooks/data";
import { send } from "emailjs-com";
import Swal from "sweetalert2";

const Demo = () => {
  const [country, setCountry] = useState();

  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
    company: "",
    job: "",
    phone: "",
    country: "",
  });

  const [error, setError] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
    company: "",
    job: "",
    phone: "",
    country: "",
  });

  useEffect(() => {
    const fetchData = () => {
      let options = countries
        .sort((a, b) => (a?.name > b.name ? -1 : 1))
        .map((c) => ({
          label: c.name,
          value: c.name,
        }));
      setCountry(options);
    };
    fetchData();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    const findSelectedCountryLabel = country.filter(
      // eslint-disable-next-line eqeqeq
      (state) => state.value == toSend.country
    );

    toSend.country = findSelectedCountryLabel[0]?.value;

    if (!toSend.from_name) {
      setError((prevS) => ({ ...prevS, from_name: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, from_name: "" }));
    }

    if (!toSend.to_name) {
      setError((prevS) => ({ ...prevS, to_name: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, to_name: "" }));
    }

    if (!toSend.message) {
      setError((prevS) => ({ ...prevS, message: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, message: "" }));
    }

    if (!toSend.reply_to) {
      setError((prevS) => ({ ...prevS, reply_to: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, reply_to: "" }));
    }

    if (!toSend.country) {
      setError((prevS) => ({ ...prevS, country: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, country: "" }));
    }

    if (!toSend.job) {
      setError((prevS) => ({ ...prevS, job: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, job: "" }));
    }

    if (!toSend.company) {
      setError((prevS) => ({ ...prevS, company: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, company: "" }));
    }

    if (!toSend.phone) {
      setError((prevS) => ({ ...prevS, phone: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, phone: "" }));
    }

    if (!toSend.employee) {
      setError((prevS) => ({ ...prevS, employee: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, employee: "" }));
    }

    setLoading(true);

    if (
      toSend.from_name &&
      toSend.to_name &&
      toSend.reply_to &&
      toSend.job &&
      toSend.company &&
      toSend.phone &&
      toSend.employee &&
      toSend.country &&
      toSend.message
    ) {
      send("service_zsud14x", "template_kpc2tkc", toSend, "VZeCAos13i6l8fa6N")
        .then((response) => {
          setLoading(false);
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Request Sent Successfully",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={style.main}>
      <div className={style.main_container}>
        <Image
          src='assets/images/header.jpg'
          alt='career'
          className={style.main_container_bg}
        />
        <h3 className={style.main_container_text}>Demo</h3>
      </div>
      <div className={style.main_card}>
        <h5 className={style.main_card_h5}>
          Transform how you hire and retain a diverse global workforce with the
        </h5>
        <h1 className={style.main_card_h1}>
          <span className={style.main_card_span}>AI-powered</span> Talent
          Intelligence Platform
        </h1>
      </div>
      <Row md={12} className={style.main_row}>
        <Col md={6}>
          <div className={style.main_right}>
            <Image
              src='assets/images/demo.png'
              width={500}
              height={500}
              alt='demo'
            />
          </div>
        </Col>
        <Col md={6}>
          <div className={style.main_left}>
            <h4>Request A Live Demo</h4>
            <Form onSubmit={onSubmit}>
              <Row className='mb-3'>
                <Form.Group as={Col}>
                  <MyField
                    label='First Name'
                    type='text'
                    name='from_name'
                    placeholder='First Name'
                    value={toSend.from_name}
                    onChange={handleChange}
                    error={error.from_name}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <MyField
                    label='Last Name'
                    type='text'
                    placeholder='Last Name'
                    name='to_name'
                    value={toSend.to_name}
                    onChange={handleChange}
                    error={error.to_name}
                  />
                </Form.Group>
              </Row>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Email Address'
                  type='email'
                  name='reply_to'
                  placeholder='Email Address'
                  value={toSend.reply_to}
                  onChange={handleChange}
                  error={error.reply_to}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Job Title'
                  type='text'
                  placeholder='Job Title'
                  name='job'
                  value={toSend.job}
                  onChange={handleChange}
                  error={error.job}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Company Name'
                  type='text'
                  placeholder='Company Name'
                  name='company'
                  value={toSend.company}
                  onChange={handleChange}
                  error={error.company}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Phone Number'
                  type='number'
                  placeholder='Phone Number'
                  name='phone'
                  value={toSend.phone}
                  onChange={handleChange}
                  error={error.phone}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Number of Employees'
                  type='number'
                  placeholder='Number of Employees'
                  name='employee'
                  value={toSend.employee}
                  onChange={handleChange}
                  error={error.employee}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <Form.Label className='text-label'>Country</Form.Label>
                <select
                  className={`text-input ${error.country && "error-active"}`}
                  placeholder='State of Origin'
                  name='country'
                  value={toSend.country}
                  onChange={handleChange}
                >
                  <option>Select Country</option>
                  {country
                    ?.sort((a, b) => (a?.label > b?.label ? 1 : -1))
                    ?.map((countryOption) => {
                      return (
                        <option
                          value={countryOption?.label}
                          label={countryOption?.label}
                          key={countryOption?.label}
                        >
                          {countryOption?.label}
                        </option>
                      );
                    })}
                </select>
                {error.country && (
                  <p className='helperError'>{error.country}</p>
                )}
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress2'>
                <MyField
                  label='How did you hear about us?'
                  as='textarea'
                  rows={4}
                  name='message'
                  value={toSend.message}
                  onChange={handleChange}
                  error={error.message}
                  placeholder='How did you hear about us?'
                />
              </Form.Group>

              <MyButton loader={loading} type='submit' text='Submit' />
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Demo;
