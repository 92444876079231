import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { MyButton } from "../../Hooks/TextInput";
import style from "./Solutions.module.scss";
import { GoPrimitiveDot } from "react-icons/go";
import {
  customer,
  finance,
  fraud,
  health,
  offers,
  providers,
  retention,
} from "../../Hooks/data";
import { useNavigate } from "react-router-dom";

const Solutions = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.main_container}>
        <Image
          src='assets/images/header.jpg'
          alt='career'
          className={style.main_container_bg}
        />
        <h3 className={style.main_container_text}>Solutions</h3>
      </div>
      <div className={style.container}>
        <h2 className={style.container_h2}>How Do You Want to AI Innovate?</h2>
        <p className={style.container_p}>
          Flexible Options to Unlock Your Business’s Potential
        </p>
        <Row md={12} className={style.container_row}>
          <Row className='mb-5'>
            <h2 className={style.container_head}>
              Financial Service Use Cases
            </h2>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Infrastructure and Operations
            </h5>
            <div className={style.container_cards}>
              {finance.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    btnClass={style.container_cards_card_btn}
                    onClick={() => navigate(`${card.label}`)}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className='mt-5 mb-5'>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Customer Acquisition
            </h5>
            <div className={style.container_cards}>
              {customer.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className='mt-5 mb-5'>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Personalized Offers
            </h5>
            <div className={style.container_cards}>
              {offers.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className='mt-5 mb-5'>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Customer Service and Retention
            </h5>
            <div className={style.container_cards}>
              {retention.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className='mt-5 mb-5'>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Fraud and Compliance
            </h5>
            <div className={style.container_cards}>
              {fraud.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>
        </Row>

        <Row md={12} className={`${style.container_row} mb-5 mt-5`}>
          <Row className='mb-5'>
            <h2 className={style.container_head}>HealthCare Use Cases</h2>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Providers
            </h5>
            <div className={style.container_cards}>
              {providers.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className='mt-5 mb-5'>
            <h5 className={style.container_sub}>
              <GoPrimitiveDot /> Public Health
            </h5>
            <div className={style.container_cards}>
              {health.map((card) => (
                <div className={style.container_cards_card}>
                  <p className={style.container_cards_card_label}>
                    {card.label}
                  </p>
                  <p className={style.container_cards_card_text}>{card.text}</p>

                  <MyButton
                    text='Learn More '
                    onClick={() => navigate(`${card.label}`)}
                    btnClass={style.container_cards_card_btn}
                  />
                </div>
              ))}
            </div>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default Solutions;
