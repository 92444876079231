import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg";
import "./Page404.styles.scss";

const Page404 = () => {
  return (
    <div className='page-not-found'>
      {/* <img src="/img/user.webp" alt="comrade" className="page-not-found-img" /> */}
      <p className='page-not-found-text'>
        This page is under development. <br />
        Please go to home page
      </p>
      <Link to='/' className='page-not-found-home-btn'>
        <HomeIcon className='page-not-found-home-btn-icon' /> Home
      </Link>
    </div>
  );
};

export default Page404;
