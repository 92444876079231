import React from "react";
import Banner from "../../components/banner/Banner.component";
import Menu from "../../components/dashboardContents/Menu.component";
import Services from "../../components/services/Services.component";
import style from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <div className={style.main}>
      <section className={style.main_section1}>
        <Banner />
      </section>
      <section className={style.main_section2}>
        <Menu />
        <Services />
      </section>
    </div>
  );
};

export default Dashboard;
