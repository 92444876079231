import React from "react";
import { Form } from "react-bootstrap";
import ButtonLoader from "../components/Loader/ButtonLoader.component";
import "./Input.styles.scss";
// import ButtonLoader from "../components/Loader/ButtonLoader.component";

export const MyButton = ({ ...props }) => {
  return (
    <div>
      <button
        type='submit'
        onClick={props.onClick}
        disabled={props.disabled}
        className={` button ${props.btnClass} `}
      >
        {props.loader ? <ButtonLoader /> : props.text || "Submit"}
      </button>
    </div>
  );
};

export const MyField = ({ ...props }) => {
  return (
    <div>
      <Form.Label className='text-label'>{props.label}</Form.Label>
      <Form.Control
        {...props}
        className={`text-input ${props.error && "error-active"}`}
      />
      {props.error && <p className='helperError'>{props.error}</p>}
    </div>
  );
};
