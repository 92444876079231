import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { FaBars, FaTimes } from "react-icons/fa";
import { MyButton } from "../../Hooks/TextInput";
import { Image } from "react-bootstrap";
import TranslatePage from "../../components/translator/Translator";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div>
      <nav>
        <p className='logo' onClick={() => (window.location.href = "/")}>
          <Image
            src='/assets/images/namabafat.png'
            alt='Nabafat'
            width={60}
            height={100}
          />{" "}
          Nabafat.AI
        </p>

        <div>
          <ul id='navbar' className={clicked ? "#navbar active" : "#navbar"}>
            {/* <li>
              <p
                className={`${pathname === "/solutions" ? "active" : ""} `}
                onClick={() => navigate("solutions")}
              >
                Solutions
              </p>
            </li> */}
            <li>
              <p
                className={`${pathname === "/solutions" ? "active" : ""} `}
                onClick={() => (window.location.href = "solutions")}
              >
                Solutions
              </p>
            </li>
            {/* <li>
              <p
                className={`${pathname === "/partners" ? "active" : ""} `}
                onClick={() => navigate("partners")}
              >
                Partners
              </p>
            </li> */}
            <li>
              <p
                className={`${pathname === "/career" ? "active" : ""} `}
                onClick={() => (window.location.href = "career")}
              >
                Career
              </p>
            </li>
            <li>
              <p
                className={`${pathname === "/about" ? "active" : ""} `}
                onClick={() => (window.location.href = "about")}
              >
                About
              </p>
            </li>
            <li>
              <p
                className={`${pathname === "/contact" ? "active" : ""} `}
                onClick={() => (window.location.href = "contact")}
              >
                Contact
              </p>
            </li>
            <li>
              <div>
                <MyButton
                  text='Request a demo'
                  onClick={() => (window.location.href = "demo")}
                  btnClass='content_btn'
                />
              </div>
            </li>

            {/* <li>
              <TranslatePage />
            </li> */}
          </ul>
        </div>

        <div id='mobile'>
          <div id='bar' className='icon' onClick={handleClick}>
            {clicked ? <FaTimes size={30} /> : <FaBars size={30} />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
