import * as React from "react";
import "./Loader.styles.scss";

const ButtonLoader = () => {
  return (
    <div className="custom-loader">
      <div className="custom-loader__swing"></div>
      <div className="custom-loader__swing"></div>
      <div className="custom-loader__swing"></div>
    </div>
  );
};

export default ButtonLoader;
