import React from "react";
import style from "./Career.module.scss";
import { CiSignpostR1 } from "react-icons/ci";
import { Col, Image, Row } from "react-bootstrap";
import { coreValues, positions } from "../../Hooks/data";
import { MyButton } from "../../Hooks/TextInput";
import { useNavigate } from "react-router-dom";

const Career = () => {
  const navigate = useNavigate();
  return (
    <div className={style.main}>
      <div className={style.main_container}>
        <Image
          src='assets/images/header.jpg'
          alt='career'
          className={style.main_container_bg}
        />
        <h3 className={style.main_container_text}>Careers at Nabafat.ai</h3>
      </div>

      <div className={style.main_values}>
        <h2 className={style.main_values_header}>Our Core values</h2>
        <p className={style.main_values_text}>
          We are looking for incredible engineers of all backgrounds to join our
          team and embrace our company values. Our team is dedicated to building
          these core values into our products:
        </p>
        <div className={style.main_values_lists}>
          {coreValues.map((v) => (
            <div className={style.main_values_lists_list}>
              <p className={style.main_values_lists_list_label}>{v.label}</p>
              <p className={style.main_values_lists_list_text}>{v.text}</p>
            </div>
          ))}
        </div>
      </div>

      <Row md={12} className={style.main_position}>
        <Col md={4} className={style.main_position_left}>
          <h3 className={style.main_position_left_head}>Open Positions</h3>
          <div className={style.main_position_left_lists}>
            {positions.map((pos) => (
              <div className={style.main_position_left_lists_items}>
                <div>
                  <CiSignpostR1 color='#2596be' size={30} />
                </div>
                <div className={style.main_position_left_lists_items_list}>
                  <p
                    className={style.main_position_left_lists_items_list_label}
                  >
                    {pos.label}
                  </p>
                  <p className={style.main_position_left_lists_items_list_text}>
                    {pos.text}
                  </p>
                </div>
              </div>
            ))}
            <MyButton text='Contact Us' onClick={() => navigate("/contact")} />
          </div>
        </Col>
        <Col md={6} className={style.main_position_right}>
          <Image
            src='assets/images/position.svg'
            alt='Careers'
            width={800}
            height={550}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Career;
