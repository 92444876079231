import { Routes, Route } from "react-router-dom";
import { UtilProvider } from "./Context/UtilContext";
import "./App.css";
import AppLayout from "./Layouts/AppLayout/AppLayout.layout";
import Page404 from "./components/Page404/Page404.page";
import Dashboard from "./pages/dashboard/Dashboard";
import Contact from "./pages/contact/Contact.page";
import Demo from "./pages/demo/Demo.page";
import Solutions from "./pages/solutions/Solutions.page";
import Career from "./pages/career/Career.page";
import About from "./pages/about/About.page";
import { Suspense } from "react";
import Loader from "./components/Loader/Loader.component";
import Desc from "./components/Desc";

function App() {
  return (
    <div className='App'>
      <Desc />
      <Suspense
        fallback={
          <div className='d-flex justify-content-center align-items-center min-vh-100'>
            {/* Loading Please Wait... */}
            <Loader />
          </div>
        }
      >
        <Routes>
          {/* Protected routes */}
          <Route
            path='/'
            element={
              <UtilProvider>
                {" "}
                <AppLayout />
              </UtilProvider>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path='contact' element={<Contact />} />
            <Route path='demo' element={<Demo />} />
            <Route path='solutions' element={<Solutions />} />
            <Route path='career' element={<Career />} />
            <Route path='about' element={<About />} />

            <Route path='*' element={<Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
