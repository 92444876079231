import React from "react";
import { Image } from "react-bootstrap";
import style from "./Menu.module.scss";
import { TiInputChecked } from "react-icons/ti";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

const Menu = () => {
  const sponsor = [
    { id: 0, logo: "/assets/images/logo1.png" },
    { id: 1, logo: "/assets/images/logo2.png" },
    { id: 2, logo: "/assets/images/logo3.png" },
    { id: 3, logo: "/assets/images/logo4.png" },
    { id: 4, logo: "/assets/images/logo5.png" },
  ];

  const cards = [
    { id: 0, logo: "/assets/images/automation.png", label: "Automation AI" },
    {
      id: 1,
      logo: "/assets/images/animation.png",
      label: "Analytics & Insights",
    },
    { id: 2, logo: "/assets/images/cloud.png", label: "Cloud Infastructure" },
    {
      id: 3,
      logo: "/assets/images/secure.png",
      label: "Fast & Secure Platform",
    },
  ];

  return (
    <div>
      <div className={style.sponsor}>
        {sponsor.map((sp) => (
          <Zoom right cascade key={sp.id}>
            <Image src={sp.logo} width={120} height={70} alt={sp.id} />
          </Zoom>
        ))}
      </div>

      {/* <div className={style.cards}>
        {cards.map((card) => (
          <div className={style.card}>
            <Image
              src={card.logo}
              width={120}
              height={100}
              alt={card.label}
              key={card.id}
            />
            <p>{card.label}</p>

            <MyButton text='Learn More ' btnClass={style.card_btn} />
          </div>
        ))}
      </div> */}

      <div className={style.features}>
        <Slide left>
          <div className={style.features_contents}>
            <h5 className={style.features_contents_header}>Features</h5>
            <p className={style.features_contents_semiheader}>
              Take Your Company To Next Level With AI
            </p>
            <p className={style.features_contents_text}>
              Achieve your business objectives with tailored solutions using
              modern technology and artificial intelligence.
            </p>
            <div className={style.features_contents_checks}>
              <div>
                <TiInputChecked size={30} color='#2596be' />
              </div>
              <div>Custom business solutions using technology</div>
            </div>

            <div className={style.features_contents_checks}>
              <div>
                <TiInputChecked size={30} color='#2596be' />
              </div>
              <div>Automation for any kind of company</div>
            </div>

            <div className={style.features_contents_checks}>
              <div>
                <TiInputChecked size={30} color='#2596be' />
              </div>
              <div>Creating cloud infrastructure for any company</div>
            </div>

            <div className={style.features_contents_checks}>
              <div>
                <TiInputChecked size={30} color='#2596be' />
              </div>
              <div>Cyber security solutions using AI technology</div>
            </div>
          </div>
        </Slide>

        <Slide right>
          <div className={style.features_banner}>
            <Image
              src='/assets/images/features.png'
              alt='banner'
              width={600}
              height={500}
            />
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Menu;
