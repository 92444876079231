import "./AppLayout.styles.scss";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Newsletter from "../../components/newsletter/Newsletter.component";
import Foot from "../../components/foot/Foot.component";

const AppLayout = () => {
  return (
    <>
      <div className='app-layout'>
        <Navbar />
        <div className='app-layout-container'>
          <div className='app-layout-container-contents'>
            <Outlet />
          </div>
        </div>
        <div className='app-layout-footer'>
          <Newsletter />
          <Foot />
        </div>
      </div>
    </>
  );
};

export default AppLayout;
