import React from "react";
import { Image } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin, FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdDoubleArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MyButton } from "../../Hooks/TextInput";
import style from "./Foot.module.scss";

const Foot = () => {
  const navigate = useNavigate();

  return (
    <div className={style.footer}>
      <div className={style.main}>
        {/* brand */}
        <div className={style.main_brand}>
          {/* logo */}
          <div
            className={style.main_brand_logo}
            onClick={() => (window.location.href = "/")}
          >
            <Image
              src='/assets/images/namabafat.png'
              alt='Nabafat'
              width={60}
              height={100}
            />{" "}
            Nabafat.AI
          </div>
          {/* info */}
          <p className={style.main_brand_info}>
            Nabafat.AI is a central platform to manage <br /> vision
            intelligence inside smart machines. <br />
            Successfully introduce AI in dynamic <br />
            environments without the need for AI <br /> experts.
          </p>
          {/* social icons */}
          <div className={style.main_brand_socials}>
            <FaFacebookSquare className={style.main_brand_socials_icon} />
            <GrInstagram className={style.main_brand_socials_icon} />
            <FaLinkedin className={style.main_brand_socials_icon} />
            <FaTwitter className={style.main_brand_socials_icon} />
          </div>
        </div>

        {/* pages */}
        <div className={style.main_links}>
          <div className={style.main_pages}>
            <p className={style.main_pages_label}>Pages</p>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "solutions")}>
                Solutions
              </div>
            </div>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "career")}>
                Career
              </div>
            </div>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "about")}>About</div>
            </div>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "contact")}>
                Contact
              </div>
            </div>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "demo")}>Demo</div>
            </div>
          </div>

          {/* Solutions */}
          <div className={style.main_pages}>
            <p className={style.main_pages_label}>Solutions</p>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "solutions")}>
                Financial Service{" "}
              </div>
            </div>
            <div className={style.main_pages_bullet}>
              <MdDoubleArrow />
              <div onClick={() => (window.location.href = "solutions")}>
                HealthCare
              </div>
            </div>
          </div>
        </div>

        {/* Demo */}
        <div className={style.main_pages}>
          <MyButton
            text='Request a demo'
            onClick={() => (window.location.href = "demo")}
            btnClass='content_btn'
          />
        </div>
      </div>
      {/* Footer Bottom */}
      <div className='footer-bottom border-top mt-4'>
        <p className='text-center pt-2'>
          {/* <hr /> */}
          Copyright &copy;{new Date().getFullYear()} - Nabafat.ai. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
};

export default Foot;
