import React, { useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { MyButton, MyField } from "../../Hooks/TextInput";
import style from "./Contact.module.scss";
import { FaMailBulk } from "react-icons/fa";
import { MdLocationPin, MdWifiCalling3 } from "react-icons/md";
import { send } from "emailjs-com";
import Swal from "sweetalert2";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
    subject: "",
  });

  const [error, setError] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
    subject: "",
  });

  const onSubmit = (e) => {
    if (!toSend.from_name) {
      setError((prevS) => ({ ...prevS, from_name: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, from_name: "" }));
    }

    if (!toSend.to_name) {
      setError((prevS) => ({ ...prevS, to_name: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, to_name: "" }));
    }

    if (!toSend.message) {
      setError((prevS) => ({ ...prevS, message: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, message: "" }));
    }

    if (!toSend.reply_to) {
      setError((prevS) => ({ ...prevS, reply_to: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, reply_to: "" }));
    }

    if (!toSend.subject) {
      setError((prevS) => ({ ...prevS, subject: "This field is required" }));
    } else {
      setError((prevS) => ({ ...prevS, subject: "" }));
    }

    setLoading(true);
    e.preventDefault();
    if (
      toSend.from_name &&
      toSend.to_name &&
      toSend.message &&
      toSend.reply_to &&
      toSend.subject
    ) {
      send("service_zsud14x", "template_dpbcrpb", toSend, "VZeCAos13i6l8fa6N")
        .then((response) => {
          setLoading(false);
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Message Sent Successfully",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const contacts = [
    {
      id: 1,
      icon: <MdWifiCalling3 size={20} color='#2596be' />,
      text: "+1(510)-356-7983",
    },
    {
      id: 2,
      icon: <FaMailBulk size={20} color='#2596be' />,
      text: "contact@nabafat.ai",
    },
    {
      id: 3,
      icon: <MdLocationPin size={20} color='#2596be' />,
      text: " 3400 Cottage Way, Ste G2 #13879 Sacramento , California 95825. USA",
    },
  ];

  return (
    <div className={style.main}>
      <div className={style.main_container}>
        <Image
          src='assets/images/header.jpg'
          alt='career'
          className={style.main_container_bg}
        />
        <h3 className={style.main_container_text}>Contact Nabafat.AI</h3>
      </div>
      <Row md={12} className={style.main_row}>
        <Col md={7}>
          <div className={style.main_left}>
            <h4>Get In Touch</h4>
            <Form onSubmit={onSubmit}>
              <Row className='mb-3'>
                <Form.Group as={Col}>
                  <MyField
                    label='First Name'
                    type='text'
                    name='from_name'
                    placeholder='First Name'
                    value={toSend.from_name}
                    onChange={handleChange}
                    error={error.from_name}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <MyField
                    label='Last Name'
                    type='text'
                    name='to_name'
                    placeholder='Last Name'
                    value={toSend.to_name}
                    onChange={handleChange}
                    error={error.to_name}
                  />
                </Form.Group>
              </Row>

              <Form.Group className='mb-3' controlId='formGridAddress1'>
                <MyField
                  label='Email Address'
                  type='email'
                  name='reply_to'
                  placeholder='Email Address'
                  value={toSend.reply_to}
                  onChange={handleChange}
                  error={error.reply_to}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress2'>
                <MyField
                  label='Subject'
                  type='text'
                  name='subject'
                  placeholder='Subject'
                  value={toSend.subject}
                  onChange={handleChange}
                  error={error.subject}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formGridAddress2'>
                <MyField
                  label='Message'
                  as='textarea'
                  rows={4}
                  name='message'
                  placeholder='Message'
                  value={toSend.message}
                  onChange={handleChange}
                  error={error.message}
                />
              </Form.Group>

              <MyButton loader={loading} type='submit' text='Submit' />
            </Form>
          </div>
        </Col>
        <Col md={5}>
          <div className={style.main_right}>
            <h4 className={style.main_right_head}>Contact Address:</h4>
            <p className={style.main_right_text}>
              If you face any type of problem, reach out to our support team
              anytime from anywhere.
            </p>
            <div className={style.main_right_details}>
              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  className={style.main_right_details_check}
                >
                  <div>{contact.icon}</div>
                  <div>{contact.text}</div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
