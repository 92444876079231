import React from "react";
import style from "./News.module.scss";
import { Col, Image, Row } from "react-bootstrap";
import { MyButton } from "../../Hooks/TextInput";
import { BiMailSend } from "react-icons/bi";
import Slide from "react-reveal/Slide";

const Newsletter = () => {
  const icon = <BiMailSend size={30} />;
  return (
    <div className={style.container}>
      <Slide left>
        <div className={style.container_left}>
          <div className={style.container_left_hello}>
            <h4 className={style.container_left_hello_header}>
              Have Any Project In Mind?
            </h4>
            <MyButton text={icon} btnClass={style.container_left_hello_btn} />
          </div>
        </div>
      </Slide>

      <Slide right>
        <div className={style.container_right}>
          <div className={style.container_right_news}>
            <Image
              src='/assets/images/newsletter.png'
              width={200}
              height={230}
              alt='newletter'
              className={style.container_right_news_image}
            />

            <div className={style.container_right_news_mail}>
              <h4 className={style.container_right_news_mail_head}>
                Subscribe To Our News Letter!
              </h4>
              <p className={style.container_right_news_mail_text}>
                Subscribe to our newsletter and stay updated.
              </p>
              <form className={style.container_right_news_mail_input}>
                <input
                  type='email'
                  required
                  placeholder='Your Email Address'
                  className={style.container_right_news_mail_input_field}
                />
                <MyButton text='Subscribe' />
              </form>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Newsletter;
